const navList = [
    {
        id: 1,
        title: "Pricing",
    },
    {
        id: 2,
        title: "Product",
    },
    {
        id: 3,
        title: "About Us",
    },
    {
        id: 4,
        title: "Careers",
    },
    {
        id: 5,
        title: "Community",
    },
];
export { navList };
